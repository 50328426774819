import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, useParams, useHistory } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import App from './App';
import AppStateProvider, { useAppState } from './state';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { ChatProvider } from './components/ChatProvider';
import { ParticipantProvider } from './components/ParticipantProvider';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import Nothing from './components/Nothing/Nothing';
import { IntlProvider } from 'react-intl';
import localeService from './utils/locale-service';
import { setupTourlaneCookies, SnowplowScript, setup, trackPageView } from '@tourlane/tracking';

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://88c666d7c13e48ce8e07a2eca5b873d9@o119095.ingest.us.sentry.io/4507820668747776',
  tracesSampleRate: 0,
  environment: process.env.REACT_APP_DEPLOYED_TO,
  ignoreErrors: [
    // Error in the twilio-video lib that doesn't seem to break the app
    "null is not an object (evaluating 'a.frameWidth')",
  ],
});

const initSnowplow = () => {
  setupTourlaneCookies();
  setup(null);
};

const VideoApp = () => {
  const { error, setError, setUser, getTravelRequest, locale, setLocale } = useAppState();
  const connectionOptions = useConnectionOptions();
  const { isAuthenticated, user: auth0User, isLoading: auth0IsLoading, getAccessTokenSilently } = useAuth0();
  const { travelRequestUuid } = useParams<{ travelRequestUuid: string }>();
  const history = useHistory();
  const [messagesInitialized, setmessagesInitialized] = useState(false);
  const [messages, setMessages] = useState({});

  useEffect(() => {
    if (process.env.REACT_APP_DEPLOYED_TO) {
      trackPageView();
    }
  }, []);

  /**
    * What are we trying to achieve?
    * Some agents may sometimes or always use the customer link. The customer link (does not have auth=true param)
    * does not force the login via Auth0. However we can try to login silently. getAccessTokenSilently will open
    * an invisible iframe to Auth0 and try to check if the user is already logged in via Google. If yes the login
    * state here will also be updated.
    */
  useEffect(() => {
    if (!auth0IsLoading && !isAuthenticated) {
      getAccessTokenSilently().catch(() => { });
    }
  }, [auth0IsLoading, isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    const localeFromUrl = new URLSearchParams(window.location.search).get('lang');
    setLocale(localeFromUrl || window.navigator.language);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isAuthenticated) {
      setUser({ displayName: auth0User?.name, photoURL: auth0User?.picture });
      Sentry.setUser({ username: auth0User?.name });
    } else {
      const fetchTravelRequest = async (id?: string) => {
        if (id) {
          const data = await getTravelRequest(id);
          setUser({ displayName: data.customerFullName });
        }
      };
      fetchTravelRequest(travelRequestUuid).catch(() => history.replace('/'));
      Sentry.setUser({ id: travelRequestUuid });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, auth0User, travelRequestUuid]);

  useEffect(() => {
    const translations = localeService.getMessages(locale);
    if (translations) {
      setMessages(translations);
      document.title = translations['page_title'] || document.title;
    }
    setmessagesInitialized(true);
  }, [locale]);

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <ParticipantProvider>
        <ChatProvider>
          {messagesInitialized && (
            <IntlProvider messages={messages} key={locale} locale={locale} defaultLocale="en">
              <UnsupportedBrowserWarning>
                <App />
              </UnsupportedBrowserWarning>
            </IntlProvider>
          )}
        </ChatProvider>
      </ParticipantProvider>
    </VideoProvider>
  );
};

const onRedirectCallback = (appState: any) => {
  window.location.href = appState?.returnTo || window.location.pathname;
};

export const ReactApp = () => (
  <>
    {process.env.REACT_APP_DEPLOYED_TO && (
      <SnowplowScript environment={process.env.REACT_APP_DEPLOYED_TO} appId="video-chat" callback={initSnowplow} />
    )}
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      scope={process.env.REACT_APP_AUTH0_SCOPES}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <AppStateProvider>
            <Switch>
              <PrivateRoute exact path="/t/:travelRequestUuid" component={VideoApp} />
              <Route path="*">
                <Nothing />
              </Route>
            </Switch>
          </AppStateProvider>
        </Router>
      </MuiThemeProvider>
    </Auth0Provider>
  </>
);

ReactDOM.render(<ReactApp />, document.getElementById('root'));
