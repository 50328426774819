import React from 'react';
import Video from 'twilio-video';
import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import IntroContainer from '../IntroContainer/IntroContainer';

const useStyles = makeStyles({
  container: {
    marginTop: '2.5em',
  },
  paper: {
    padding: '1.5em',
    textAlign: 'center',
  },
  heading: {
    marginBottom: '0.5em',
  },
  link: {
    marginTop: '1em',
    display: 'block',
  },
});

export default function UnsupportedBrowserWarning({ children }: { children: React.ReactElement }) {
  const classes = useStyles();
  const intl = useIntl();

  if (!Video.isSupported) {
    return (
      <IntroContainer>
        <Typography variant="h4" className={classes.heading}>
          {intl.formatMessage({ id: 'unsupportedBrowser.heading' })}
        </Typography>
        <Typography>
          {intl.formatMessage({ id: 'unsupportedBrowser.message' })}
          <Link href="https://www.google.com/chrome/" target="_blank" rel="noopener" className={classes.link}>
            {intl.formatMessage({ id: 'unsupportedBrowser.linkText' })}
          </Link>
        </Typography>
      </IntroContainer>
    );
  }
  return children;
}
